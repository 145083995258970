<script setup>
import { defineEmits } from 'vue'
import NavMenu from '@/components/menu/NavMenuComponent'

const emit = defineEmits(['getCheckId'])

function checkId(response) {
    emit('getCheckId', response)
}
</script>

<template>
    <div class="header">
        <router-link to="/" class="logo">
            <img src="@/assets/islamic-digital-lane/images/brand/logo.png" alt="" srcset="">
        </router-link>
        <NavMenu @check-id="checkId" />
    </div>
</template>

<style scoped>
.header {
    display: grid;
    grid-template-columns: 0.1fr 1fr;
    padding: 0 30px;
    border-bottom: 2px solid gray;
}

.logo {
    text-decoration: none;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    justify-content: start;
}

.logo img {
    height: 100%;
    width: 80px;
}
</style>