<template>
    <div class="footer">
        <div class="info">
            <div class="address">
                <h2 class="footer-title">CONTACT INFO</h2>
                <div class="footer-details">
                    <div class="location">
                        <font-awesome-icon :icon="['fas', 'location-dot']" />  
                        <P>850 Central Pkwy E, STE 248 Plano, TX 75074 USA.</P>
                    </div>
                    <div class="email">
                        <font-awesome-icon :icon="['fas', 'envelope']" /> 
                        <P>info@islamicdigitalane.com</P>
                    </div>
                </div>
            </div>
            <div class="about">
                <h2 class="footer-title">OUR COMPANY</h2>
                <div class="footer-links">
                    <router-link to="/"><font-awesome-icon :icon="['fas', 'chevron-right']" /> Home</router-link>
                    <router-link to="/contact-us"><font-awesome-icon :icon="['fas', 'chevron-right']" /> Contact Us</router-link>
                </div>
            </div>
        </div>
        <div class="copyright">
            <p>© Copyright - Islamic Digital Lane</p>
        </div>
    </div>
</template>

<style scoped>
.info {
    background-color: #1c2650;
    color: white;
    min-height: 32vh;
    overflow: hidden;
    display: grid;
    grid-template-columns: 0.58fr 0.42fr;
    gap: 40px;
}

.address {
    padding: 30px 0;
    justify-self: end;
}

.address > *, .about > *{
    padding: 10px 0;
}

.footer-details > * {
    display: grid;
    grid-template-columns: 0.07fr 1fr;
}

.footer-details > * > * {
    padding: 5px 0;
}

.about {
    padding: 30px 0;
    justify-self: start;
}

.footer-links {
    display: grid;
}

.footer-links > * {
    text-decoration: none;
    color: white;
    padding: 5px 0;
}

.footer-links > *:hover {
    color: rgb(99, 154, 206);
}

.copyright {
    background-color: #2c9299;
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

@media only screen and (max-width: 768px) {
    .info {
        grid-template-columns: 0.5fr 0.5fr;
        padding: 20px;
    }

    .address {
        justify-self: start;
    }

    .about {
        justify-self: start;
    }
}

@media only screen and (max-width: 400px) {
    .info {
        grid-template-columns: 1fr;
        padding: 20px; 
    }

    .address {
        justify-self: start;
    }

    .about {
        justify-self: start;
    }
}
</style>