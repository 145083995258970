<template>
  <component :is="$route.meta.layout || 'div'">
      <router-view></router-view>

  </component>
</template>

<script>

</script>

<style>
html {
  scroll-behavior: smooth;
}

ul, li, a, p, h1, h2, h3, h4, h5, h6 {
  padding: 0;
  margin: 0;
}

button, input, select {
  outline: none;
  border: none;
}

button {
  cursor: pointer;
}

button:hover {
  opacity: 0.8;
}

.text-center {
  text-align: center;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

body {
  margin: auto;
}
</style>
