<script setup>
import Header from '@/learning-center/layouts/website/organs/HeaderLayout.vue'
import Footer from '@/learning-center/layouts/website/organs/FooterLayout.vue'
import LaodingComp from '@/components/spinner/RollerComponent.vue'
import { useCommonStore } from '@/stores/common'

const commonStore = useCommonStore()

</script>

<template>
  <div class="loader" v-if="commonStore.loading == true">
      <LaodingComp></LaodingComp>
  </div>
  <Header />
  <div class="content">
      <slot />
  </div>
  <Footer />
</template>

<style scoped>
.main-header {
  height: 10vh;
}

.content {
  min-height: 50vh;
}

.footer {
  height: 40vh;
}
</style>