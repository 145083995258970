<script setup>
import Header from '@/islamic-digital-lane/layouts/website/organs/app/HeaderLayout.vue'
import Footer from '@/islamic-digital-lane/layouts/website/organs/app/FooterLayout.vue'
import LaodingComp from '@/components/spinner/RollerComponent.vue'
import { useCommonStore } from '@/stores/common'
import { onMounted } from 'vue'

const commonStore = useCommonStore()

onMounted(() => {
    commonStore.getData()
})
</script>

<template>
  <div class="loader" v-if="commonStore.loading == true">
      <LaodingComp></LaodingComp>
  </div>
  <div class="app-container">
    <Header :logo="(commonStore.data) ? commonStore.data['brand_logo'] : ''" />
    <div class="app-content">
        <slot />
    </div>
    <Footer />
</div>
</template>

<style scoped>
.app-container > * {
float: left;
width: 100%;
}

.app-content {
min-height: 58vh;
margin-top: 12vh;
}
</style>