import { createRouter, createWebHistory } from 'vue-router'

// Layouts
import IdlFrntPageLayout from '@/islamic-digital-lane/layouts/website/FrontPageLayout'
import LcFrntPageLayout from '@/learning-center/layouts/website/FrontPageLayout'
import AppLayout from '@/islamic-digital-lane/layouts/website/AppLayout'
import AuthLayout from '@/learning-center/layouts/website/AuthLayout'
import DashboardLayout from '@/learning-center/layouts/dashboard/DashboardLayout'

const routes = [
    {
        path: '/',
        name: 'LandingPage',
        component: () => import('@/islamic-digital-lane/modules/website/LandingPage'),
        meta: { layout: IdlFrntPageLayout }
    },
    {
        path: '/about-us',
        name: 'AboutUsPage',
        component: () => import('@/islamic-digital-lane/modules/website/AboutUsPage'),
        meta: { layout: IdlFrntPageLayout }
    },
    {
        path: '/career',
        name: 'CareerPage',
        component: () => import('@/islamic-digital-lane/modules/website/CareerPage'),
        meta: { layout: IdlFrntPageLayout }
    },
    {
        path: '/contact-us',
        name: 'ContactUsPage',
        component: () => import('@/islamic-digital-lane/modules/website/ContactUsPage'),
        meta: { layout: IdlFrntPageLayout }
    },
    {
        path: '/vision-mission',
        name: 'VisionMissionPage',
        component: () => import('@/islamic-digital-lane/modules/website/VisionMissionPage'),
        meta: { layout: IdlFrntPageLayout }
    },

    // Products
 
    {
        path: '/product/:id/details',
        name: "PrDetails",
        component: () => import('@/islamic-digital-lane/modules/website/products/ProductDetailsPage'),
        meta: { layout: IdlFrntPageLayout }
    },

    // Islamic news and events
    {
        path: '/islamic-news-events/news',
        name: 'VolunteerContributionPage',
        component: () => import('@/islamic-digital-lane/modules/website/islamic-news-events/IslamicNewsPage'),
        meta: { layout: IdlFrntPageLayout }
    },
    {
        path: '/islamic-news-events/events',
        name: 'GlobalEventsPage',
        component: () => import('@/islamic-digital-lane/modules/website/islamic-news-events/IslamicEventsPage'),
        meta: { layout: IdlFrntPageLayout }
    },
    {
        path: '/:pageName/:id',
        name: 'NewsEventDetailsPage',
        component: () => import('@/islamic-digital-lane/modules/website/islamic-news-events/NewsEventDetailsPage'),
        meta: { layout: IdlFrntPageLayout }
    },

    {
        path: '/islamic-apps',
        name: 'IslamicAppsPage',
        component: () => import('@/islamic-digital-lane/modules/website/IslamicAppsPage'),
        meta: { layout: IdlFrntPageLayout }
    },
    {
        path: '/it-support-and-services',
        name: 'ItSupportServicePage',
        component: () => import('@/islamic-digital-lane/modules/website/ItSupportServicePage'),
        meta: { layout: IdlFrntPageLayout }
    },

    // Apps
    {
        path: '/app/:id',
        name: 'AppFrontPage',
        component: () => import('@/islamic-digital-lane/modules/website/apps/AppFrontPage'),
        meta: { layout: AppLayout }
    },
    {
        path: '/app/:id/app-features',
        name: 'AppFeaturePage',
        component: () => import('@/islamic-digital-lane/modules/website/apps/AppFeaturePage'),
        meta: { layout: AppLayout }
     },
     {
        path: '/app/:id/how-to-purchase',
        name: 'HowToPurchasePage',
        component: () => import('@/islamic-digital-lane/modules/website/apps/HowToPurchasePage'),
        meta: { layout: AppLayout }
     },
     {
        path: '/app/:id/contact-us',
        name: 'AppContactUsPage',
        component: () => import('@/islamic-digital-lane/modules/website/apps/ContactUsPage'),
        meta: { layout: AppLayout }
     },
     {
        path: '/app/:id/privacy-policy',
        name: 'PrivacyPolicyPage',
        component: () => import('@/islamic-digital-lane/modules/website/apps/PrivacyPolicyPage'),
        meta: { layout: AppLayout }
     },

    // Learning Center
    
    {
        path: '/learning-center',
        name: 'LearningCenter',
        component: () => import('@/learning-center/modules/website/LearningCenter'),
        meta: { layout: LcFrntPageLayout }
    },
    {
        path: '/management-login',
        name: 'ManagementLoginPage',
        component: () => import('@/auth/ManagementLoginPage'),
        meta: { layout: AuthLayout, notAuthenticated: true }
    },
    {
        path: '/student-login',
        name: 'LoginPage',
        component: () => import('@/auth/LoginPage'),
        meta: { layout: AuthLayout, notAuthenticated: true }
    },
    {
        path: '/teacher-login',
        name: 'TeacherLoginPage',
        component: () => import('@/auth/TeacherLoginPage'),
        meta: { layout: AuthLayout, notAuthenticated: true }
    },
    {
        path: '/forgot-pass',
        name: 'forgotPasswordPage',
        component: () => import('@/components/auth/ForgotPasswordComponent.vue'),
        meta: { layout: AuthLayout, notAuthenticated: true }
    },

    {
        path: '/registration',
        name: 'RegistrationPage',
        component: () => import('@/auth/RegistrationPage'),
        meta: { layout: AuthLayout, notAuthenticated: true }
    },  
    {
        path: '/reg-teacher',
        name: 'TeacherRegistrationPage',
        component: () => import('@/auth/RegistrationPageTeacher'),
        meta: { layout: AuthLayout, notAuthenticated: true }
    },
    {
        path: '/management-registration',
        name: 'ManagementRegistrationPage',
        component: () => import('@/auth/ManagementRegistrationPage'),
        meta: { layout: AuthLayout, notAuthenticated: true }
    },


    // {
    //     path: '/teacher-registration',
    //     name: 'RegistrationPageTeacher',
    //     component: () => import('@/auth/RegistrationPageTeacher'),
    //     meta: { layout: AuthLayout, notAuthenticated: true }
    // },
    {
        path: '/my-registration',
        name: 'StudentRegistrationPage',
        component: () => import('@/learning-center/modules/dashboard/student/StudentRegistrationPage.vue'),
        meta: { layout: DashboardLayout , requiresAuth: true }
    },
    {
        path: '/dashboard/my-admin',
        name: 'MyAdminPage',
        component: () => import('@/learning-center/modules/dashboard/MyAdminPage'),
        meta: { layout: DashboardLayout , requiresAuth: true }
    },
    {
        path: '/dashboard/my-advisor',
        name: 'MyAdvisor',
        component: () => import('@/learning-center/modules/dashboard/MyAdvisor'),
        meta: { layout: DashboardLayout , requiresAuth: true }
    },
    {
        path: '/dashboard/my-students',
        name: 'MyStudentPage',
        component: () => import('@/learning-center/modules/dashboard/teacher/MyStudentPage'),
        meta: { layout: DashboardLayout , requiresAuth: true }
    },
    {
        path: '/dashboard/my-teachers',
        name: 'MyTeacherPage',
        component: () => import('@/learning-center/modules/dashboard/student/MyTeacherPage'),
        meta: { layout: DashboardLayout , requiresAuth: true }
    },
    {
        path: '/dashboard/my-schedule',
        name: 'MySchedulePage',
        component: () => import('@/learning-center/modules/dashboard/MySchedulePage'),
        meta: { layout: DashboardLayout , requiresAuth: true }
    },
    {
        path: '/dashboard/help-center',
        name: 'HelpCenter',
        component: () => import('@/learning-center/modules/dashboard/HelpCenter'),
        meta: { layout: DashboardLayout , requiresAuth: true }
    },

    {
        path: '/verify-teacher',
        name: 'RegistrationPageTeacher',
        component: () => import('@/learning-center/modules/dashboard/TeacherVerificationPage.vue'),
        meta: { layout: DashboardLayout , requiresAuth: true }
    },
    {
        path: '/learning-center/areas/:id',
        name: 'AreaPage',
        component: () => import('@/learning-center/modules/website/AreaPage'),
        meta: { layout: LcFrntPageLayout }
    },

    {
        path: '/learning-center/programs/:id',
        name: 'ProgramPage',
        component: () => import('@/learning-center/modules/website/ProgramPage'),
        meta: { layout: LcFrntPageLayout }
    },
    {
        path: '/learning-center/course/:id',
        name: 'CourseDetailsPage',
        component: () => import('@/learning-center/modules/website/CourseDetailsPage'),
        meta: { layout: LcFrntPageLayout }
    },
    {
        path: '/dashboard',
        name: 'DashboardPage',
        component: () => import('@/learning-center/modules/dashboard/DashboardPage'),
        meta: { layout: DashboardLayout, requiresAuth: true }
    },
    {
        path: '/dashboard/profile',
        name: 'ProfilePage',
        component: () => import('@/learning-center/modules/dashboard/ProfilePage'),
        meta: { layout: DashboardLayout, requiresAuth: true }
    },
    {
        path: '/dashboard/join-class',
        name: 'JoinClassPage',
        component: () => import('@/learning-center/modules/dashboard/teacher/JoinClassPage'),
        meta: { layout: DashboardLayout, requiresAuth: true }
    }, 
    {
        path: '/dashboard/teacher/join-class',
        name: 'JoinClassTeacher',
        component: () => import('@/learning-center/modules/dashboard/teacher/JoinClassTeacher'),
        meta: { layout: DashboardLayout, requiresAuth: true }
    }, 
    {
        path: '/dashboard/teacher-verify',
        name: 'JoinClassPage',
        component: () => import('@/learning-center/modules/dashboard/teacher/JoinClassPage'),
        meta: { layout: DashboardLayout, requiresAuth: true }
    },
    {
        path: '/dashboard/my-classes/:id',
        name: 'ClassListPage',
        component: () => import('@/learning-center/modules/dashboard/ClassListPage'),
        meta: { layout: DashboardLayout, requiresAuth: true }
    },
    {
        path: '/dashboard/my-courses',
        name: 'CoursePage',
        component: () => import('@/learning-center/modules/dashboard/student/CoursePage'),
        meta: { layout: DashboardLayout, requiresAuth: true }
    },

    {
        path: '/dashboard/ongoing-courses',
        name: 'OngoingCoursePage',
        component: () => import('@/learning-center/modules/dashboard/teacher/OngoingCoursesPage'),
        meta: { layout: DashboardLayout, requiresAuth: true }
    },
    {
        path: '/dashboard/all-courses',
        name: 'AllCoursePage',
        component: () => import('@/learning-center/modules/dashboard/teacher/AllCoursesPage.vue'),
        meta: { layout: DashboardLayout, requiresAuth: true }
    },
    {
        path: '/dashboard/my-courses/teacher-schedule/:courseId/:teacherId',
        name: 'TeacherSchedulePage',
        component: () => import('@/learning-center/modules/dashboard/TeacherSchedulePage.vue'),
        meta: { layout: DashboardLayout, requiresAuth: true }
    },
    {
        path: '/dashboard/my-courses/schedule/:id',
        name: 'ClassSchedulePage',
        component: () => import('@/learning-center/modules/dashboard/student/ClassSchedulePage'),
        meta: { layout: DashboardLayout, requiresAuth: true }
    },
    {
        path: '/dashboard/advisors',
        name: 'AdvisorListPage',
        component: () => import('@/learning-center/modules/dashboard/management/AdvisorListPage'),
        meta: { layout: DashboardLayout, requiresAuth: true }
    },
    {
        path: '/dashboard/old-teachers',
        name: 'OldTeacherListPage',
        component: () => import('@/learning-center/modules/dashboard/management/teacher/OldTeacherListPage'),
        meta: { layout: DashboardLayout, requiresAuth: true }
    },
    {
        path: '/dashboard/current-teachers',
        name: 'CurrentTeacherListPage',
        component: () => import('@/learning-center/modules/dashboard/management/teacher/CurrentTeacherListPage'),
        meta: { layout: DashboardLayout, requiresAuth: true }
    },
    {
        path: '/dashboard/new-teachers',
        name: 'NewTeacherListPage',
        component: () => import('@/learning-center/modules/dashboard/management/teacher/NewTeacherListPage'),
        meta: { layout: DashboardLayout, requiresAuth: true }
    },
    {
        path: '/dashboard/teacher-details/:id',
        name: 'TeacherDetailsPage',
        component: () => import('@/learning-center/modules/dashboard/management/teacher/TeacherDetailsPage'),
        meta: { layout: DashboardLayout, requiresAuth: true }
    },
    {
        path: '/dashboard/old-students',
        name: 'OldStudentListPage',
        component: () => import('@/learning-center/modules/dashboard/management/student/OldStudentListPage'),
        meta: { layout: DashboardLayout, requiresAuth: true }
    },
    {
        path: '/dashboard/current-students',
        name: 'CurrentStudentListPage',
        component: () => import('@/learning-center/modules/dashboard/management/student/CurrentStudentListPage'),
        meta: { layout: DashboardLayout, requiresAuth: true }
    },
    {
        path: '/dashboard/new-students',
        name: 'NewStudentListPage',
        component: () => import('@/learning-center/modules/dashboard/management/student/NewStudentListPage'),
        meta: { layout: DashboardLayout, requiresAuth: true }
    },
    {
        path: '/dashboard/student-details/:id',
        name: 'StudentDetailsPage',
        component: () => import('@/learning-center/modules/dashboard/management/student/StudentDetailsPage'),
        meta: { layout: DashboardLayout, requiresAuth: true }
    },
    {
        path: '/dashboard/in-registration-process',
        name: 'InRegistrationProcess',
        component: () => import('@/learning-center/modules/dashboard/management/student/InRegistrationProcess'),
        meta: { layout: DashboardLayout, requiresAuth: true }
    },
    // Advisor
    {
        path: '/advisor/dashboard/schedule/list',
        name: 'ScheduleList',
        component: () => import('@/learning-center/modules/dashboard/advisor/ScheduleList'),
        meta: { layout: DashboardLayout, requiresAuth: true }
    },
    {
        path: '/advisor/dashboard/schedule/create',
        name: 'createSchedule',
        component: () => import('@/learning-center/modules/dashboard/advisor/createSchedule'),
        meta: { layout: DashboardLayout, requiresAuth: true }
    },
    {
        path: '/dashboard/enrollment-schedule/:id',
        name: 'createScheduleFromEnrollment',
        component: () => import('@/learning-center/modules/dashboard/advisor/createScheduleFromEnrollment.vue'),
        props: (route) => ({ teacher: route.query.teacher, student: route.query.student,course: route.query.course }),
        meta: { layout: DashboardLayout, requiresAuth: true }
    },
    {
        path: '/advisor/dashboard/enrollment',
        name: 'EnrollmentList',
        component: () => import('@/learning-center/modules/dashboard/advisor/EnrollmentList'),
        meta: { layout: DashboardLayout, requiresAuth: true }
    },
    {
        path: '/advisor/dashboard/students',
        name: 'StudentList',
        component: () => import('@/learning-center/modules/dashboard/advisor/StudentList'),
        meta: { layout: DashboardLayout, requiresAuth: true }
    },
    {
        path: '/advisor/dashboard/teachers',
        name: 'TeacherList',
        component: () => import('@/learning-center/modules/dashboard/advisor/TeacherList'),
        meta: { layout: DashboardLayout, requiresAuth: true }
    },
    {
        path: '/advisor/dashboard/courses',
        name: 'CourseList',
        component: () => import('@/learning-center/modules/dashboard/advisor/CourseList'),
        meta: { layout: DashboardLayout, requiresAuth: true }
    },
    // dashboard settings
    {
        path: '/dashboard/settings/update-profile',
        name: 'UpdateProfile',
        component: () => import('@/learning-center/modules/dashboard/UpdateProfile.vue'),
        meta: { layout: DashboardLayout, requiresAuth: true }
    },

    {
        path: '/dashboard/settings/update-password',
        name: 'UpdatePassword',
        component: () => import('@/learning-center/modules/dashboard/UpdatePasswordPage.vue'),
        meta: { layout: DashboardLayout, requiresAuth: true }
    },
    {
        path: '/dashboard/course-modules/:id',
        name: 'CourseModulesPage',
        component: () => import('@/learning-center/modules/dashboard/CourseModulesPage'),
        meta: { layout: DashboardLayout, requiresAuth: true }
    },
    {
        path: '/dashboard/my-purchases',
        name: 'PurchasePage',
        component: () => import('@/learning-center/modules/dashboard/PurchasePage'),
        meta: { layout: DashboardLayout, requiresAuth: true }
    },

    // Course Enrollment
    {
        path: '/learning-center/course/:id/enroll',
        name: "CourseEnrollment",
        component: () => import('@/learning-center/modules/website/enrollment/CourseEnrollmentPage'),
        meta: { layout: LcFrntPageLayout, requiresAuth: true }
    },
    {
        path: '/learning-center/cart',
        name: "CartPage",
        component: () => import('@/learning-center/modules/website/enrollment/CartPage'),
        meta: { layout: LcFrntPageLayout, requiresAuth: true }
    },
    {
        path: '/learning-center/checkout',
        name: "CheckoutPage",
        component: () => import('@/learning-center/modules/website/enrollment/CheckoutPage'),
        meta: { layout: LcFrntPageLayout, requiresAuth: true }
    },
    {
        path: '/learning-center/success',
        name: "PaymentSuccess",
        component: () => import('@/learning-center/modules/website/enrollment/PaymentSuccess'),
        meta: { layout: DashboardLayout }
    },
    {
        path: '/learning-center/failed',
        name: "PaymentFailed",
        component: () => import('@/learning-center/modules/website/enrollment/PaymentFailed'),
        meta: { layout: LcFrntPageLayout }
    },
    {
        path: '/:catchAll(.*)*',
        name: "NotfoundPage",
        component: () => import('@/learning-center/modules/website/NotfoundPage'),
        meta: { layout: LcFrntPageLayout }
    },
]

var router = createRouter({
    routes,
    history: createWebHistory('/')
})

function isLoggedIn() {
    return localStorage.getItem('token')
}
  
  router.beforeEach((to, from, next) => {
   if(to.matched.some(record => record.meta.requiresAuth)) {
      if(!isLoggedIn()) {
        next({
            name: 'LoginPage'
        })
      } else {
        next()
      }
   } else if(to.matched.some(record => record.meta.notAuthenticated)) {
    if(isLoggedIn()) {
        next({
            name: 'CoursePage'
        })
      } else {
        next()
      }
   } else {
    next()
   }
  })

export default router