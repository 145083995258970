import { createApp } from 'vue'
import App from './App.vue'
import Routes from './routes/index'

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import Select2 from 'vue3-select2-component';
import 'tailwindcss/tailwind.css';
import PrimeVue from 'primevue/config';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import { createPinia } from 'pinia';

library.add(fas, far, fab)
dom.watch();

createApp(App)
.component('font-awesome-icon', FontAwesomeIcon)
.component('Select2', Select2)
.use(VueSweetalert2)
.use(Routes)
.use(PrimeVue)
.use(createPinia())
.mount('#app')
