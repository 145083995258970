<script setup>
import Header from '@/islamic-digital-lane/layouts/website/organs/HeaderLayout.vue'
import Footer from '@/islamic-digital-lane/layouts/website/organs/FooterLayout.vue'
import { ref } from 'vue'
import LaodingComp from '@/components/spinner/RollerComponent.vue'
import { useCommonStore } from '@/stores/common'

const commonStore = useCommonStore()

let getChecked = ref(true)

function getCheckId(response) {
  getChecked.value = response
}
</script>

<template>
  <div class="loader" v-if="commonStore.loading == true">
      <LaodingComp></LaodingComp>
  </div>
    <Header @get-check-id="getCheckId" />
    <div class="content">
        <slot :getChecked="getChecked" />
    </div>
    <Footer />
</template>

<style scoped>
.header {
  height: 10vh;
}

.content {
  min-height: 50vh;
}

.footer {
  height: 40vh;
}
</style>