<script setup>
import Card from '@/components/apps/card/CardComponent'
import { useCommonStore } from '@/stores/common'
import { useRouter } from 'vue-router'
import { onMounted } from "vue"

const commonStore = useCommonStore()
const router = useRouter()

onMounted(() => {
    commonStore.getData(router.currentRoute.value.params.id)
})
</script>

<template>
    <div class="footer">
        <div class="left-col">
            <Card>
                <template #header>
                    <h2>Islamic Heart Softeners</h2>
                </template>
                <template #content>
                    <div><font-awesome-icon :icon="['fas', 'fa-location-dot']" /> &nbsp; <span>{{ (commonStore.data) ? commonStore.data['address'] : '' }}</span></div>
                    <div><font-awesome-icon :icon="['fas', 'fa-envelope']" /> &nbsp; <span>{{ (commonStore.data) ? commonStore.data['email'] : '' }}</span></div>
                </template>
            </Card>
        </div>
        <div class="right-col">
            <p class="col-title">Useful Links</p>
            <div class="col-contents">
                <router-link class="link-item" :to="'/app/'+$route.params.id+'/'"><font-awesome-icon :icon="['fas', 'fa-square-arrow-up-right']" /> &nbsp; Home</router-link>
                <router-link class="link-item" :to="'/app/'+$route.params.id+'/app-features'"><font-awesome-icon :icon="['fas', 'fa-square-arrow-up-right']" /> &nbsp; App Features</router-link>
                <router-link class="link-item" :to="'/app/'+$route.params.id+'/how-to-purchase'"><font-awesome-icon :icon="['fas', 'fa-square-arrow-up-right']" /> &nbsp; How to Purchase</router-link>
                <router-link class="link-item" :to="'/app/'+$route.params.id+'/contact-us'"><font-awesome-icon :icon="['fas', 'fa-square-arrow-up-right']" /> &nbsp; Contact Us</router-link>
                <router-link class="link-item" :to="'/app/'+$route.params.id+'/privacy-policy'"><font-awesome-icon :icon="['fas', 'fa-square-arrow-up-right']" /> &nbsp; Privacy Policy</router-link>
            </div>
        </div>
        <div class="copyright">
            <span>© Copyright <strong>Islamic Digital Lane</strong>. All Rights Reserved</span>
        </div>
    </div>
</template>

<style scoped>
.footer {
    background-color: #1a4e36;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 0.2fr;
    grid-template-areas:
    'left-col right-col'
    'copyright copyright'
    ;
    font-family: math;
    min-height: 30vh;
}

.left-col {
    grid-area: left-col;
    padding: 50px;
    display: flex;
    justify-content: end;
}

.slot-content > * {
    display: block;
    color: white;
    font-size: 15px;
    padding: 5px 0;
    font-family: math;
}

.right-col {
    grid-area: right-col;
    padding: 50px;
}

.col-title {
    color: white;
    font-weight: bold;
    font-size: 17px;
}

.col-contents {
    margin-top: 15px;
}

.link-item {
    text-decoration: none;
    color: white;
    display: block;
    padding: 5px 0;
    font-size: 13px;
}

.copyright {
    grid-area: copyright;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 14px;
    background-color: #297552;
    padding: 20px 0;
}
</style>